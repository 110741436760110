import React from 'react'
import Routes from '../../routes'
import LinkBlock from '../LinkBlock'
import IconInstagram from '../_svgs/IconInstagram'
import IconFacebook from '../_svgs/IconFacebook'
import IconLinkedIn from '../_svgs/IconLinkedIn'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import AnimateSplitText from '../_animation/AnimateSplitText'
import {
  LatestContainer,
  LatestFooter,
  LatestFooterTitle,
  LatestImage,
  LatestImages,
  LatestImagesContainer,
  LatestImagesWrapper,
  LatestLabelA,
  LatestLabelB,
  LatestMain,
  LatestThumbnail,
  LatestTitle,
  LatestWrapper,
} from './index.style'
import { graphql, useStaticQuery } from 'gatsby'

const Latest = () => {
  let instaData = null

  instaData = useStaticQuery(graphql`
    query InstagramQuery {
      allInstagramContent(limit: 4, sort: { fields: timestamp, order: DESC }) {
        edges {
          node {
            caption
            media_type
            thumbnail_url
            permalink
            localImage {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
      }
    }
  `)

  const filteredImages = !instaData
    ? null
    : instaData.allInstagramContent.edges.map(post => {
        const type = post.node.media_type

        const thumbnail =
          type === 'VIDEO'
            ? post.node.thumbnail_url
            : post.node.localImage.childImageSharp.gatsbyImageData

        return {
          type,
          thumbnail: thumbnail,
          permalink: post.node.permalink,
          caption: post.node.caption,
        }
      })

  return (
    <LatestContainer>
      <LatestMain>
        <LatestWrapper>
          <LatestLabelA>
            <AnimateSlideIn direction="left">Keep up to date</AnimateSlideIn>
          </LatestLabelA>
          <LatestLabelB>
            <AnimateSlideIn direction="right">Follow The LAAB</AnimateSlideIn>
          </LatestLabelB>
          <LatestTitle>
            <AnimateSplitText heading>Latest from The LAAB IG</AnimateSplitText>
          </LatestTitle>
        </LatestWrapper>

        <LatestImagesContainer>
          <AnimateSlideIn>
            <LatestImages>
              <LatestImagesWrapper>
                {filteredImages &&
                  React.Children.toArray(
                    filteredImages.map(post => (
                      <LatestImage href={post.permalink}>
                        {post.type === 'VIDEO' ? (
                          <LatestThumbnail
                            src={post.thumbnail}
                            alt={post.caption}
                          />
                        ) : (
                          <GatsbyImage
                            loading="eager"
                            placeholder="dominantColor"
                            image={post.thumbnail}
                            alt={post.caption}
                          />
                        )}
                      </LatestImage>
                    ))
                  )}
              </LatestImagesWrapper>
            </LatestImages>
          </AnimateSlideIn>
        </LatestImagesContainer>

        <LatestFooter>
          <LatestFooterTitle>
            <AnimateSlideIn>Follow us</AnimateSlideIn>
          </LatestFooterTitle>

          <AnimateSlideIn>
            <LinkBlock
              url={Routes.instagram}
              icon={<IconInstagram />}
              text="@thelaartbox"
            />
          </AnimateSlideIn>

          <AnimateSlideIn>
            <LinkBlock
              url={Routes.facebook}
              icon={<IconFacebook />}
              text="thelaartbox"
            />
          </AnimateSlideIn>

          <AnimateSlideIn>
            <LinkBlock
              url={Routes.linkedin}
              icon={<IconLinkedIn />}
              text="The LA ART BOX"
            />
          </AnimateSlideIn>
        </LatestFooter>
      </LatestMain>
    </LatestContainer>
  )
}

export default Latest
