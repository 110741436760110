import React, { useEffect, useState } from 'react'
import { ScrollWrapper } from '../components/Scroll/index.style'
import SEO from '../components/SEO'
import { colors } from '../styles/vars/colors.style'
import ScrollSection from '../components/ScrollSection'
import AboutIntro from '../components/AboutIntro'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { AboutIntroBackground } from '../components/AboutIntro/index.style'
import InViewSection from '../components/InViewSection'
import AboutFounder from '../components/AboutFounder'
import { AboutFounderImage } from '../components/AboutFounder/index.style'
import AnimateImage from '../components/_animation/AnimateImage'
import AboutLAAB from '../components/AboutLAAB'
import { AboutLAABImage } from '../components/AboutLAAB/index.style'
import AboutMBM from '../components/AboutMBM'
import PageLinks from '../components/PageLinks'
import AboutContributors from '../components/AboutContributors'
import Latest from '../components/Latest'
import { isDesktop } from 'react-device-detect'

const IndexPage = ({ data }) => {
  const {
    title,
    seoDescription,
    backgroundImage,
    introText,
    introImage,
    founderTextA,
    founderTextB,
    founderImage,
    laabText,
    theLaabImage,
    madeByManyText,
    partners,
    contributors,
  } = data.allContentfulPageOurStory.nodes[0]
  const [showArrow, setShowArrow] = useState(true)

  useEffect(() => {
    if (showArrow && isDesktop) {
      window.scroll.on('scroll', args => {
        if (showArrow && args.scroll.x > 300 && args.scroll.x < 400) {
          setShowArrow(false)
        }
      })
    }
  }, [showArrow, setShowArrow])

  return (
    <>
      <SEO title={title} description={seoDescription.seoDescription} />

      <InViewSection>
        <AboutIntroBackground id="aboutIntroBackground">
          <GatsbyImage
            loading="eager"
            placeholder="dominantColor"
            image={backgroundImage.gatsbyImageData}
            alt=""
          />
        </AboutIntroBackground>
      </InViewSection>

      <ScrollWrapper color={colors.ourStory}>
        <ScrollSection width={1920}>
          <AboutIntro text={introText} img={introImage} />
        </ScrollSection>

        <ScrollSection
          id="aboutFounder"
          width={940}
          backgroundColor={colors.ourStory}
        >
          <AboutFounder textA={founderTextA} textB={founderTextB} />
        </ScrollSection>

        <ScrollSection width={1021} backgroundColor={colors.ourStory}>
          <AboutFounderImage>
            <AnimateImage>
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={founderImage.gatsbyImageData}
                alt={founderImage.description}
              />
            </AnimateImage>
          </AboutFounderImage>
        </ScrollSection>

        <ScrollSection width={1510} backgroundColor={colors.light2}>
          <AboutLAAB content={laabText} />
        </ScrollSection>

        <ScrollSection width={781} backgroundColor={colors.light2}>
          <AboutLAABImage>
            <AnimateImage>
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={theLaabImage.gatsbyImageData}
                alt={theLaabImage.description}
              />
            </AnimateImage>
          </AboutLAABImage>
        </ScrollSection>

        <ScrollSection width={964} backgroundColor={colors.light2}>
          <AboutMBM content={madeByManyText} />
        </ScrollSection>

        <ScrollSection width={1460} backgroundColor={colors.light2}>
          <PageLinks
            type="partners"
            links={partners}
            labelA="LAAB Partners"
            labelB="& friends"
            allowSmoothScroll
          />
        </ScrollSection>

        <ScrollSection backgroundColor={colors.light2}>
          <AboutContributors contributors={contributors} />
        </ScrollSection>

        <ScrollSection
          id="latest"
          offset="180"
          repeat
          width={974}
          backgroundColor={colors.dark}
        >
          <Latest />
        </ScrollSection>
      </ScrollWrapper>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query OurStoryQuery {
    allContentfulPageOurStory {
      nodes {
        title
        seoDescription {
          seoDescription
        }
        backgroundImage {
          gatsbyImageData(quality: 80)
        }
        introText {
          raw
        }
        introImage {
          gatsbyImageData(quality: 80)
          title
          description
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
        founderTextA {
          raw
        }
        founderTextB {
          raw
        }
        founderImage {
          gatsbyImageData(quality: 80, width: 1287, height: 1361)
          description
        }
        laabText {
          raw
        }
        theLaabImage {
          gatsbyImageData(quality: 80)
          description
        }
        madeByManyText {
          raw
        }
        partners {
          name
          slug
        }
        contributors
      }
    }
  }
`
