import styled, { css } from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const AboutFounderMain = styled.div`
  position: relative;
  padding-top: 0;
  padding-bottom: 4rem;
  ${horizontalPadding()}

  ${mq.horizontal} {
    padding-right: ${horizontalScale(316)};
    padding-bottom: ${horizontalScale(133)};
    padding-left: ${horizontalScale(162)};
  }
`

const founderTextCSS = css`
  font-weight: ${font.weight.light};
  font-size: 2.4rem;
  line-height: 1.2;
  letter-spacing: -0.02em;

  ${mq.horizontal} {
    font-size: ${horizontalScale(24)};
  }
`

export const AboutFounderTextA = styled.div`
  ${founderTextCSS}
`

export const AboutFounderTextB = styled.div`
  margin-top: 6.4rem;
  ${founderTextCSS}

  ${mq.horizontal} {
    margin-top: ${horizontalScale(64)};
  }
`

export const AboutFounderTag = styled.p`
  position: relative;
  width: 28.9rem;
  height: 12.4rem;
  margin-top: 6.4rem;

  ${mq.horizontal} {
    width: ${horizontalScale(469)};
    height: ${horizontalScale(184)};
    margin-top: ${horizontalScale(64)};
  }
`

export const AboutFounderTagA = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 4.7rem;
  height: 12.3rem;

  ${mq.horizontal} {
    width: ${horizontalScale(68)};
    height: ${horizontalScale(184)};
  }
`

export const AboutFounderTagB = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  width: 17.3rem;
  height: 5.9rem;

  ${mq.horizontal} {
    width: ${horizontalScale(259)};
    height: ${horizontalScale(92)};
  }
`

export const AboutFounderTagC = styled.span`
  position: absolute;
  left: 6.5rem;
  bottom: 0;
  width: 14rem;
  height: 5.9rem;

  ${mq.horizontal} {
    left: ${horizontalScale(104)};
    width: ${horizontalScale(212)};
    height: ${horizontalScale(92)};
  }
`

export const AboutFounderImage = styled.div`
  width: 100%;
  height: 100%;
`
