import React from 'react'
import PropTypes from 'prop-types'
import { AboutLAABMain, AboutLAABText, AboutLAABTitle } from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import RichText from '../RichText'

const AboutLAAB = ({ content }) => (
  <AboutLAABMain>
    <AboutLAABTitle>
      <AnimateSplitText heading>We have pride in our space</AnimateSplitText>
    </AboutLAABTitle>

    <AboutLAABText>
      <RichText content={content} />
    </AboutLAABText>
  </AboutLAABMain>
)

AboutLAAB.propTypes = {
  content: PropTypes.object,
}

export default AboutLAAB
