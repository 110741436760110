import React from 'react'
import PropTypes from 'prop-types'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import RichText from '../RichText'
import Photo from '../Photo'
import SlideMask from '../SlideMask'
import IconArrowheadRight from '../_svgs/IconArrowheadRight'
import { animation } from '../../styles/vars/animation.style'
import {
  AboutIntroMain,
  AboutIntroTitle,
  AboutIntroTitleA,
  AboutIntroTitleB,
  AboutIntroContent,
  AboutIntroImage,
  AboutIntroText,
  AboutIntroScrollCTA,
} from './index.style'
import { scrollTo } from '../../utils/utils'

const AboutIntro = ({ text, img }) => {
  const entryDelay = animation.pageExitDuration

  return (
    <AboutIntroMain>
      <AboutIntroTitle aria-label="Dealing with art, ideas and issues">
        <AboutIntroTitleA aria-hidden>
          <AnimateSlideIn delay={entryDelay} direction="left">
            Dealing with
          </AnimateSlideIn>
        </AboutIntroTitleA>

        <AboutIntroTitleB aria-hidden>
          <AnimateSlideIn delay={entryDelay} direction="left">
            Art,
          </AnimateSlideIn>
        </AboutIntroTitleB>

        <AboutIntroTitleB aria-hidden>
          <AnimateSlideIn delay={entryDelay + 0.1} direction="left">
            Ideas,
          </AnimateSlideIn>
        </AboutIntroTitleB>

        <AboutIntroTitleB aria-hidden>
          <AnimateSlideIn delay={entryDelay + 0.2} direction="left">
            and
          </AnimateSlideIn>
        </AboutIntroTitleB>

        <AboutIntroTitleB aria-hidden>
          <AnimateSlideIn delay={entryDelay + 0.3} direction="left">
            Issues
          </AnimateSlideIn>
        </AboutIntroTitleB>
      </AboutIntroTitle>

      <AboutIntroContent>
        <AboutIntroText>
          <RichText content={text} delay={entryDelay} />
        </AboutIntroText>

        <AboutIntroImage>
          <Photo
            img={img}
            title={img.title}
            description={img.description}
            delay={entryDelay}
            width={760}
          />
        </AboutIntroImage>
      </AboutIntroContent>

      <AboutIntroScrollCTA onClick={() => scrollTo('aboutFounder')}>
        <AnimateSlideIn delay={animation.pageEntryDuration} direction="left">
          <SlideMask direction="horizontal">
            <IconArrowheadRight responsive />
          </SlideMask>
        </AnimateSlideIn>
      </AboutIntroScrollCTA>
    </AboutIntroMain>
  )
}

AboutIntro.propTypes = {
  text: PropTypes.object.isRequired,
  img: PropTypes.object.isRequired,
}

export default AboutIntro
