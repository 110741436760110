import React from 'react'
import PropTypes from 'prop-types'
import RichText from '../RichText'
import LinkBlock from '../LinkBlock'
import IconMail from '../_svgs/IconMail'
import {
  AboutMBMContent,
  AboutMBMFooter,
  AboutMBMFooterTitle,
  AboutMBMMain,
  AboutMBMTitle,
  AboutMBMWrapper,
} from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import Routes from '../../routes'

const AboutMBM = ({ content }) => (
  <AboutMBMMain>
    <AboutMBMTitle>
      <AnimateSplitText heading>Made possible by many</AnimateSplitText>
    </AboutMBMTitle>

    <AboutMBMWrapper>
      <AboutMBMContent>
        <RichText content={content} />
      </AboutMBMContent>

      <AboutMBMFooter>
        <AboutMBMFooterTitle>
          <AnimateSplitText>
            For more information or questions reach out
          </AnimateSplitText>
        </AboutMBMFooterTitle>

        <AnimateSlideIn>
          <LinkBlock
            icon={<IconMail />}
            text={Routes.collaborateEmail}
            url={`mailto:${Routes.collaborateEmail}`}
          />
        </AnimateSlideIn>
      </AboutMBMFooter>
    </AboutMBMWrapper>
  </AboutMBMMain>
)

AboutMBM.propTypes = {
  content: PropTypes.object,
}

export default AboutMBM
