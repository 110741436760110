import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const AboutMBMMain = styled.div`
  position: relative;
  padding-top: 0;
  padding-bottom: 6.4rem;
  ${horizontalPadding()}

  ${mq.horizontal} {
    height: ${horizontalScale(896)};
    padding: 0 ${horizontalScale(180)} 0 0;
  }
`

export const AboutMBMTitle = styled.h2`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 11rem;
  line-height: 0.84;
  text-transform: uppercase;

  ${mq.horizontal} {
    max-width: ${horizontalScale(460)};
    font-size: ${horizontalScale(180)};
    font-variation-settings: ${font.weight.secondary.extrabold};
  }
`

export const AboutMBMWrapper = styled.div`
  ${mq.belowHorizontal} {
    margin-top: 3.2rem;
  }

  ${mq.horizontal} {
    margin-top: ${horizontalScale(20)};
    padding-left: ${horizontalScale(390)};
  }
`

export const AboutMBMContent = styled.div`
  p {
    font-weight: ${font.weight.light};
    font-size: 2.4rem;
    line-height: 1.2;
    letter-spacing: -0.02em;

    ${mq.horizontal} {
      font-size: ${horizontalScale(24)};
    }
  }
`

export const AboutMBMFooterTitle = styled.h3`
  padding-bottom: 1.2rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    padding-bottom: ${horizontalScale(18)};
    font-size: ${horizontalScale(28)};
  }
`

export const AboutMBMFooter = styled.div`
  margin-top: 6.4rem;

  ${mq.horizontal} {
    margin-top: ${horizontalScale(82)};
  }
`
