import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const AboutContributorsMain = styled.div`
  position: relative;
  padding-top: 6.4rem;
  padding-bottom: 10rem;
  ${horizontalPadding()}

  ${mq.horizontal} {
    padding-top: 0;
    padding-right: ${horizontalScale(320)};
    padding-left: ${horizontalScale(320)};
    padding-bottom: ${horizontalScale(120)};
  }
`

export const AboutContributorsTitle = styled.p`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(28)};
  }
`

export const AboutContributorsContent = styled.div`
  margin-top: 2.8rem;

  ${mq.horizontal} {
    display: flex;
    margin-top: ${horizontalScale(38)};
  }
`

export const AboutContributorsColumn = styled.div`
  ${mq.horizontal} {
    width: ${horizontalScale(700)};
    white-space: initial;

    & + & {
      margin-left: ${horizontalScale(140)};
    }
  }
`

export const AboutContributorsItem = styled.span`
  display: inline-flex;
  font-family: ${font.secondary};
  font-size: 2.5rem;
  line-height: 1.3;
  text-transform: uppercase;
  font-variation-settings: ${({ i }) => {
    if (i % 10 === 0) {
      return font.weight.secondary.extrabold
    } else if (i % 2 === 0) {
      return font.weight.secondary.regular
    } else {
      return font.weight.secondary.bold
    }
  }};

  ${mq.horizontal} {
    font-size: ${horizontalScale(38)};
    line-height: 1.53;
  }
`
