import styled, { css } from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const LatestContainer = styled.div`
  overflow: hidden;
  background-color: ${colors.dark};

  ${mq.horizontal} {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
`

export const LatestMain = styled.div`
  position: relative;
  padding-top: 4.6rem;
  padding-bottom: 0;
  ${horizontalPadding()}
  color: ${colors.light};

  ${mq.horizontal} {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: ${horizontalScale(80)};
    padding-left: ${horizontalScale(140)};
  }
`

export const LatestWrapper = styled.div`
  position: relative;
  padding-top: 3.8rem;
  padding-left: 3.2rem;

  ${mq.horizontal} {
    padding: 0;
  }
`

const labelCSS = css`
  position: absolute;
  left: 0;
  font-family: ${font.tertiary};
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(12)};
  }
`

export const LatestLabelA = styled.p`
  top: 0;
  ${labelCSS}

  ${mq.horizontal} {
    top: ${horizontalScale(-48)};
    left: ${horizontalScale(-48)};
  }
`

export const LatestLabelB = styled.p`
  top: 1em;
  transform-origin: 0% 100%;
  transform: rotate(90deg);
  ${labelCSS}

  ${mq.horizontal} {
    top: ${horizontalScale(-28)};
    left: ${horizontalScale(-48)};
  }
`

export const LatestTitle = styled.h2`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 11rem;
  line-height: 0.84;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(150)};
  }
`

export const LatestImagesContainer = styled.div`
  margin-top: 4rem;
  margin-right: -3.2rem;

  ${mq.horizontal} {
    margin-top: ${horizontalScale(30)};
    margin-right: 0;
  }
`

export const LatestImages = styled.div`
  overflow: scroll;
  font-size: 0px;

  ${mq.horizontal} {
    min-width: 600px;
    overflow: hidden;
  }
`

export const LatestImagesWrapper = styled.div`
  display: inline-flex;

  ${mq.belowHorizontal} {
    padding-right: 1.6rem;
  }

  ${mq.horizontal} {
    display: flex;
  }
`

export const LatestThumbnail = styled.img`
  height: 100%;
  object-fit: cover;
`

export const LatestImage = styled.a`
  display: inline-flex;
  width: 19.5rem;
  height: 19.5rem;
  border-bottom: none;

  ${mq.horizontal} {
    display: block;
    width: ${horizontalScale(195)};
    height: ${horizontalScale(195)};
  }

  & + & {
    margin-left: 1.6rem;

    ${mq.horizontal} {
      margin-left: ${horizontalScale(18)};
    }
  }
`

export const LatestFooter = styled.div`
  display: none;

  ${mq.horizontal} {
    display: block;
    max-width: ${horizontalScale(380)};
    margin-top: ${horizontalScale(50)};
  }
`

export const LatestFooterTitle = styled.div`
  margin-bottom: 1em;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    margin-bottom: ${horizontalScale(28)};
    font-size: ${horizontalScale(28)};
  }
`
