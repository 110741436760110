import React from 'react'

const Filipino = ({
  width = 173,
  height = 59,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 173 59"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M0 58.1806H9.04508V34.4167H15.6233V26.2222H9.04508V9.01389H18.0902V0.819444H0V58.1806Z"
        fill={fill}
      />
      <path d="M21.36 58.1806H30.4051V0.819444H21.36V58.1806Z" fill={fill} />
      <path
        d="M35.3323 58.1806H53.8336V49.9861H44.3774V0.819444H35.3323V58.1806Z"
        fill={fill}
      />
      <path
        d="M56.6923 58.1806H65.7374V0.819444H56.6923V58.1806Z"
        fill={fill}
      />
      <path
        d="M70.6647 58.1806H79.7098V37.6945C87.1103 37.6945 92.4551 32.3681 92.4551 19.2569C92.4551 6.14583 87.1103 0.819444 79.7098 0.819444H70.6647V58.1806ZM79.7098 30.3194V8.19445H80.1209C81.7655 8.19445 83.41 9.83333 83.41 19.2569C83.41 28.6806 81.7655 30.3194 80.1209 30.3194H79.7098Z"
        fill={fill}
      />
      <path d="M96.12 58.1806H105.165V0.819444H96.12V58.1806Z" fill={fill} />
      <path
        d="M110.092 58.1806H119.549L115.437 13.9306H116.671L124.071 58.1806H133.527V0.819444H124.071L128.183 45.0694H126.949L119.549 0.819444H110.092V58.1806Z"
        fill={fill}
      />
      <path
        d="M149.95 59C156.529 59 162.285 53.2639 162.285 29.5C162.285 5.73611 156.529 0 149.95 0C143.372 0 137.616 5.73611 137.616 29.5C137.616 53.2639 143.372 59 149.95 59ZM149.95 51.625C148.717 51.625 147.484 50.3958 147.484 29.5C147.484 8.60417 148.717 7.375 149.95 7.375C151.184 7.375 152.417 8.60417 152.417 29.5C152.417 50.3958 151.184 51.625 149.95 51.625Z"
        fill={fill}
      />
      <path
        d="M168.477 59C170.944 59 173 56.9514 173 53.6736C173 50.3958 170.944 48.3472 168.477 48.3472C166.011 48.3472 163.955 50.3958 163.955 53.6736C163.955 56.9514 166.011 59 168.477 59Z"
        fill={fill}
      />
    </svg>
  )
}

export default Filipino
