import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { flow, horizontalPadding } from '../../styles/utils/functions.style'
import { colors } from '../../styles/vars/colors.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import backgroundNoise from '../../images/noise-small.png'

const aboutImageWidth = 958

export const AboutIntroBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 55rem;

  ${mq.horizontal} {
    right: auto;
    bottom: 0;
    width: ${horizontalScale(aboutImageWidth)};
    height: auto;
    will-change: transform;
  }
`

export const AboutIntroMain = styled.div`
  padding-top: 11.1rem;
  padding-bottom: 6.4rem;
  ${horizontalPadding()}

  ${mq.horizontal} {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 0 0 0 ${horizontalScale(462)};
  }

  :before {
    content: '';
    position: absolute;
    top: 55rem;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: ${colors.ourStory};
    background-image: url(${backgroundNoise});

    ${mq.horizontal} {
      top: 0;
      left: ${horizontalScale(aboutImageWidth)};
    }
  }
`

export const AboutIntroTitle = styled.h1`
  position: relative;

  ${mq.belowHorizontal} {
    margin-top: -1.8rem;
    padding-left: 4.8rem;
  }

  ${mq.horizontal} {
    padding-bottom: ${horizontalScale(68)};
  }
`

export const AboutIntroTitleA = styled.span`
  position: absolute;
  top: 1rem;
  left: -0.9rem;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateY(-100%);
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 5.3rem;
  line-height: 1;
  text-transform: uppercase;

  ${mq.horizontal} {
    top: 0;
    left: ${horizontalScale(-136)};
    font-size: ${horizontalScale(110)};
  }
`

export const AboutIntroTitleB = styled.span`
  display: block;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 13.9rem;
  line-height: 1;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(280)};
    line-height: 0.83;
  }

  :nth-child(n + 3) {
    margin-top: -3.6rem;

    ${mq.horizontal} {
      margin-top: 0;
    }
  }
`

export const AboutIntroContent = styled.div`
  position: relative;
  padding-top: 6.3rem;

  ${mq.horizontal} {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: ${horizontalScale(500)};
    padding-top: 0;
    padding-left: ${horizontalScale(90)};
    padding-bottom: ${horizontalScale(104)};
  }
`

export const AboutIntroImage = styled.div`
  ${mq.belowHorizontal} {
    margin-top: 6.4rem;
  }

  ${mq.horizontal} {
    order: 0;
  }
`

export const AboutIntroText = styled.div`
  font-family: ${font.weight.light};
  font-size: 2.4rem;
  letter-spacing: -0.02em;
  line-height: 1.2;
  ${flow(1.2)}

  ${mq.horizontal} {
    order: 1;
    padding-top: ${horizontalScale(36)};
    font-size: ${horizontalScale(24)};
  }
`

export const AboutIntroScrollCTA = styled.button`
  display: none;

  ${mq.horizontal} {
    display: block;
    position: absolute;
    right: ${horizontalScale(44)};
    bottom: ${horizontalScale(110)};
    display: block;
    width: ${horizontalScale(50)};
    border: none;
    background-color: transparent;
  }
`
