import React from 'react'
import PropTypes from 'prop-types'
import {
  AboutContributorsContent,
  AboutContributorsMain,
  AboutContributorsTitle,
  AboutContributorsItem,
  AboutContributorsColumn,
} from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateFadeIn from '../_animation/AnimateFadeIn'

function chunkArray(array, size) {
  if (array.length <= size) {
    return [array]
  }
  return [array.slice(0, size), ...chunkArray(array.slice(size), size)]
}

const AboutContributors = ({ contributors }) => {
  const contributorChunks = chunkArray(contributors, 50)

  return (
    <AboutContributorsMain>
      <AboutContributorsTitle>
        <AnimateSplitText>
          The LAAB was made possible thanks to our KickStarter family:
        </AnimateSplitText>
      </AboutContributorsTitle>

      <AboutContributorsContent>
        {React.Children.toArray(
          contributorChunks.map(column => (
            <AboutContributorsColumn>
              <AnimateFadeIn>
                {React.Children.toArray(
                  column.map((contributor, contributorIndex) => (
                    <AboutContributorsItem i={contributorIndex + 1}>
                      {contributor}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </AboutContributorsItem>
                  ))
                )}
              </AnimateFadeIn>
            </AboutContributorsColumn>
          ))
        )}
      </AboutContributorsContent>
    </AboutContributorsMain>
  )
}

AboutContributors.propTypes = {
  contributors: PropTypes.array,
}

export default AboutContributors
