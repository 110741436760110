import React from 'react'
import PropTypes from 'prop-types'
import {
  AboutFounderMain,
  AboutFounderTag,
  AboutFounderTagA,
  AboutFounderTagB,
  AboutFounderTagC,
  AboutFounderTextA,
  AboutFounderTextB,
} from './index.style'
import RichText from '../RichText'
import Strong from './images/Strong'
import Filipino from './images/Filipino'
import Proud from './images/Proud'
import AnimateSlideIn from '../_animation/AnimateSlideIn'

const AboutFounder = ({ textA, textB }) => (
  <AboutFounderMain>
    <AboutFounderTextA>
      <RichText content={textA} />
    </AboutFounderTextA>

    <AboutFounderTag aria-label="Strong. Filipino. Proud">
      <AboutFounderTagA>
        <AnimateSlideIn delay={0.4} direction="left">
          <Strong responsive />
        </AnimateSlideIn>
      </AboutFounderTagA>
      <AboutFounderTagB>
        <AnimateSlideIn delay={0.4} direction="left">
          <Filipino responsive />
        </AnimateSlideIn>
      </AboutFounderTagB>
      <AboutFounderTagC>
        <AnimateSlideIn delay={0.4} direction="left">
          <Proud responsive />
        </AnimateSlideIn>
      </AboutFounderTagC>
    </AboutFounderTag>

    <AboutFounderTextB>
      <RichText content={textB} delay={0.6} />
    </AboutFounderTextB>
  </AboutFounderMain>
)

AboutFounder.propTypes = {
  textA: PropTypes.object,
  textB: PropTypes.object,
}

export default AboutFounder
