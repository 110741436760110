import React from 'react'

const Strong = ({
  width = 47,
  height = 123,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 47 123"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M3.43155e-06 8.76542C3.21869e-06 13.6351 3.91667 17.5308 11.75 17.5308C23.5 17.5308 27.7431 7.1422 36.8167 7.1422C40.4722 7.1422 41.125 7.79149 41.125 8.76542C41.125 9.73936 40.1458 10.7133 34.9236 10.7133C32.9653 10.7133 31.0069 10.3887 31.0069 10.3887L31.0069 16.8816C31.0069 16.8816 32.9653 17.5308 36.2292 17.5308C43.0833 17.5308 47 13.6351 47 8.76542C47 3.89574 43.0833 -1.71203e-07 35.25 -5.13609e-07C23.5 -1.02722e-06 19.2569 10.3886 10.1181 10.3886C6.52778 10.3886 5.875 9.73936 5.875 8.76542C5.875 7.79149 6.85417 6.81755 12.0764 6.81755C14.0347 6.81755 15.9931 7.1422 15.9931 7.1422L15.9931 0.649289C15.9931 0.649289 14.0347 -1.44096e-06 10.7708 -1.58363e-06C3.91666 -1.88323e-06 3.64441e-06 3.89574 3.43155e-06 8.76542Z"
        fill={fill}
      />
      <path
        d="M0.652778 24.6528L0.652777 31.7949L39.8194 31.795L39.8194 37.6386L46.3472 37.6386L46.3472 18.8091L39.8194 18.8091L39.8194 24.6528L0.652778 24.6528Z"
        fill={fill}
      />
      <path
        d="M0.652777 39.8984L0.652777 47.0406L18.9306 47.0406C18.9306 48.9885 17.9514 49.9624 15.6667 49.9624L4.56944 49.9624C1.63194 49.9624 0.652776 50.9363 0.652776 52.8842L0.652776 58.4032L1.30555 58.4032C1.30555 58.4032 1.63194 57.1046 4.24305 57.1046L15.0139 57.1046C20.2361 57.1046 23.1736 54.1828 23.1736 48.9885L23.1736 47.0406L23.8264 47.0406C23.8264 53.8581 27.4167 57.4292 34.9236 57.4292C42.1042 57.4292 46.3472 53.2089 46.3472 47.0406L46.3472 39.8984L0.652777 39.8984ZM28.0694 47.0406L40.4722 47.0406L40.4722 47.3652C40.4722 48.9885 39.1667 50.2871 34.2708 50.2871C29.375 50.2871 28.0694 48.9885 28.0694 47.3652L28.0694 47.0406Z"
        fill={fill}
      />
      <path
        d="M7.64252e-07 69.7861C5.37201e-07 74.9804 4.56944 79.5254 23.5 79.5254C42.4306 79.5254 47 74.9804 47 69.7861C47 64.5917 42.4306 60.0467 23.5 60.0467C4.56944 60.0467 9.91303e-07 64.5917 7.64252e-07 69.7861ZM5.875 69.7861C5.875 68.8121 6.85416 67.8382 23.5 67.8382C40.1458 67.8382 41.125 68.8121 41.125 69.7861C41.125 70.76 40.1458 71.7339 23.5 71.7339C6.85416 71.7339 5.875 70.76 5.875 69.7861Z"
        fill={fill}
      />
      <path
        d="M0.652775 82.7617L0.652775 90.2286L35.9028 86.9821L35.9028 87.9561L0.652775 93.7997L0.652774 101.267L46.3472 101.267L46.3472 93.7997L11.0972 97.0461L11.0972 96.0722L46.3472 90.2286L46.3472 82.7617L0.652775 82.7617Z"
        fill={fill}
      />
      <path
        d="M-1.19285e-06 114.559C-1.39152e-06 119.104 3.91666 123 14.3611 123C19.5833 123 22.5208 121.701 22.5208 121.701L22.5208 112.287L23.5 112.287C40.1458 112.287 41.125 113.261 41.125 114.235C41.125 115.209 40.4722 115.858 33.2917 115.858C31.0069 115.858 28.0694 115.533 28.0694 115.533L28.0694 122.026C28.0694 122.026 30.3542 123 34.2708 123C43.0833 123 47 119.104 47 114.559C47 109.04 42.4306 104.495 23.5 104.495C4.56944 104.495 -9.51603e-07 109.04 -1.19285e-06 114.559ZM5.875 114.235C5.875 113.261 6.52777 112.611 16.9722 112.287L16.9722 115.533C16.9722 115.533 14.6875 115.858 12.7292 115.858C6.52777 115.858 5.875 115.144 5.875 114.235Z"
        fill={fill}
      />
    </svg>
  )
}

export default Strong
