import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { flow, horizontalPadding } from '../../styles/utils/functions.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'

export const AboutLAABMain = styled.div`
  position: relative;
  padding-top: 8.2rem;
  padding-bottom: 6.4rem;
  ${horizontalPadding()}

  ${mq.horizontal} {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding-top: 0;
    padding-right: ${horizontalScale(310)};
    padding-bottom: ${horizontalScale(130)};
    padding-left: ${horizontalScale(162)};
  }
`

export const AboutLAABTitle = styled.h2`
  max-width: 25.5rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 11rem;
  line-height: 0.84;
  text-transform: uppercase;

  ${mq.horizontal} {
    max-width: ${horizontalScale(550)};
    font-size: ${horizontalScale(180)};
    font-variation-settings: ${font.weight.secondary.extrabold};
  }
`

export const AboutLAABText = styled.div`
  ${flow(1.3)}

  ${mq.belowHorizontal} {
    margin-top: 6.4rem;
  }

  ${mq.horizontal} {
    max-width: ${horizontalScale(380)};
  }

  p {
    font-weight: ${font.weight.light};
    font-size: 2.4rem;
    line-height: 1.2;
    letter-spacing: -0.02em;

    ${mq.horizontal} {
      font-size: ${horizontalScale(24)};
    }
  }
`

export const AboutLAABImage = styled.div`
  ${mq.belowHorizontal} {
    ${horizontalPadding('left')}
    padding-bottom: 6.4rem;
  }

  ${mq.horizontal} {
    width: ${horizontalScale(606)};
  }
`
