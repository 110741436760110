import React from 'react'

const Proud = ({
  width = 140,
  height = 59,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 59"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M0 58.1806H9.004L9.004 37.6945C16.3709 37.6945 21.6914 32.3681 21.6914 19.2569C21.6914 6.14583 16.3709 0.819444 9.004 0.819444L0 0.819444L0 58.1806ZM9.004 30.3194L9.004 8.19445H9.41327C11.0504 8.19445 12.6875 9.83333 12.6875 19.2569C12.6875 28.6806 11.0504 30.3194 9.41327 30.3194H9.004Z"
        fill={fill}
      />
      <path
        d="M25.3397 58.1806H34.3437V35.2361C36.7994 35.2361 38.0272 36.4653 38.0272 39.3333V53.2639C38.0272 56.9514 39.255 58.1806 41.7106 58.1806H48.6683V57.3611C48.6683 57.3611 47.0312 56.9514 47.0312 53.6736V40.1528C47.0312 33.5972 43.3477 29.9097 36.7994 29.9097H34.3437V29.0903C42.9384 29.0903 47.4404 24.5833 47.4404 15.1597C47.4404 6.14583 42.1199 0.819444 34.3437 0.819444L25.3397 0.819444L25.3397 58.1806ZM34.3437 23.7639L34.3437 8.19445H34.753C36.7994 8.19445 38.4365 9.83333 38.4365 15.9792C38.4365 22.125 36.7994 23.7639 34.753 23.7639H34.3437Z"
        fill={fill}
      />
      <path
        d="M63.0184 59C69.5667 59 75.2966 53.2639 75.2966 29.5C75.2966 5.73611 69.5667 0 63.0184 0C56.47 0 50.7402 5.73611 50.7402 29.5C50.7402 53.2639 56.47 59 63.0184 59ZM63.0184 51.625C61.7906 51.625 60.5628 50.3958 60.5628 29.5C60.5628 8.60417 61.7906 7.375 63.0184 7.375C64.2462 7.375 65.474 8.60417 65.474 29.5C65.474 50.3958 64.2462 51.625 63.0184 51.625Z"
        fill={fill}
      />
      <path
        d="M90.4269 59C96.5659 59 101.477 54.0833 101.477 40.9722V0.819444L92.4732 0.819444V42.6111C92.4732 50.3958 91.6547 51.625 90.4269 51.625C89.199 51.625 88.3805 50.3958 88.3805 42.6111V0.819444L79.3765 0.819444V40.9722C79.3765 54.0833 84.2878 59 90.4269 59Z"
        fill={fill}
      />
      <path
        d="M106.395 58.1806L115.399 58.1806C123.175 58.1806 129.314 52.0347 129.314 29.5C129.314 6.96528 123.175 0.819444 115.399 0.819444L106.395 0.819444L106.395 58.1806ZM115.399 50.3958L115.399 8.60417H115.808C117.855 8.60417 119.492 10.2431 119.492 29.5C119.492 48.7569 117.855 50.3958 115.808 50.3958H115.399Z"
        fill={fill}
      />
      <path
        d="M135.498 59C137.954 59 140 56.9514 140 53.6736C140 50.3958 137.954 48.3472 135.498 48.3472C133.042 48.3472 130.996 50.3958 130.996 53.6736C130.996 56.9514 133.042 59 135.498 59Z"
        fill={fill}
      />
    </svg>
  )
}

export default Proud
